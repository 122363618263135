import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NotFreeAgentGuard from "guard/NotFreeAgentGuard";

const AuthGuard = ({ children }) => {
  const { authReducer } = useSelector((state: any) => {
    return { authReducer: state.authReducer };
  });

  return authReducer.token && authReducer.userData ? <NotFreeAgentGuard>{children}</NotFreeAgentGuard> : <Navigate to="/login" />;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
