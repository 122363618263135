import { Suspense, Fragment, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SplashScreen from "screens/SplashScreen";
import Auth from "layouts/auth";
import Dashbaord from "layouts/dashbaord";
import FreeAgentDashboard from "layouts/freeAgentDashboard";
import AuthGuard from "guard/AuthGuard";
import CheckIfUserVerified from "guard/CheckIfUserVerified";
import NotAuthGuard from "guard/NotAuthGuard";
import HasAuthEmailGuard from "guard/HasAuthEmailGuard";
import HasUserPhoneGuard from "guard/HasUserPhoneGuard";
import HasProductPathGuard from "guard/HasProductPathGuard";
import HasApplicationIdOrUserVerifiedGuard from "guard/HasApplicationIdOrUserVerifiedGuard";
import HasApplicationIdGuard from "guard/HasApplicationIdGuard";
import FreeAgentGuard from "guard/FreeAgentGuard";
import MarketerDashboard from "../layouts/MarketerDashboard";
import HasProductIdOrApplicationIdGuard from "guard/HasProductIdOrApplicationIdGuard";

const routes = [
  {
    exact: true,
    layout: Dashbaord,
    path: "/banking-applications",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/banking-applications")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/banking-applications/:id",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/banking-applications")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/insurance-applications",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/insurance-applications")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/insurance-applications/:id",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/insurance-applications")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/microfinance-applications",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/microfinance-applications")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/microfinance-applications/:id",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/microfinance-applications")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/",
    guard: AuthGuard,
    component: lazy(() => import("views/Home")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/credit-cards",
    guard: AuthGuard,
    component: lazy(() => import("views/products/CreditCards")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/car-loans",
    guard: AuthGuard,
    component: lazy(() => import("views/products/CarLoans")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/deposits",
    guard: AuthGuard,
    component: lazy(() => import("views/products/Deposits")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/car-insurances",
    guard: AuthGuard,
    component: lazy(() => import("views/products/CarInsurance")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/home-insurances",
    guard: AuthGuard,
    component: lazy(() => import("views/products/HomeInsurance")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/fire-theft-insurances",
    guard: AuthGuard,
    component: lazy(() => import("views/products/FireTheftInsurance")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/medical-insurances",
    guard: AuthGuard,
    component: lazy(() => import("views/products/MedicalInsurance")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/personal-accidents",
    guard: AuthGuard,
    component: lazy(() => import("views/products/PersonalAccident")),
  },
  {
    exact: true,
    layout: Auth,
    path: "/verify-user-phone",
    guard: HasProductPathGuard,
    component: lazy(() => import("views/userVerify/VerifyPhone")),
  },
  {
    exact: true,
    layout: Auth,
    path: "/verify-user-otp",
    guard: HasUserPhoneGuard,
    component: lazy(() => import("views/userVerify/VerifyOtp")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/application/personal-info",
    guard: HasProductIdOrApplicationIdGuard,
    component: lazy(() => import("views/application/personalInfo")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/application/additional-info",
    guard: HasApplicationIdGuard,
    component: lazy(() => import("views/application/additionalInfo")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/application/documents",
    guard: HasApplicationIdGuard,
    component: lazy(() => import("views/application/documents")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/personal-loans",
    guard: AuthGuard,
    component: lazy(() => import("views/products/PersonalLoans")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/home-loans",
    guard: AuthGuard,
    component: lazy(() => import("views/products/HomeLoans")),
  },
  {
    exact: true,
    layout: Auth,
    path: "/login",
    guard: NotAuthGuard,
    component: lazy(() => import("views/auth/Login")),
  },
  {
    exact: true,
    layout: Auth,
    path: "/verify-otp",
    guard: HasAuthEmailGuard,
    component: lazy(() => import("views/auth/Otp")),
  },
  {
    exact: true,
    layout: Auth,
    path: "/update-password",
    guard: HasAuthEmailGuard,
    component: lazy(() => import("views/auth/UpdatePassword")),
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: Dashbaord,
    path: "/application/max-limit",
    component: lazy(() => import("views/application/maxLimit")),
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: Dashbaord,
    path: "/application/success",
    component: lazy(() => import("views/application/success")),
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: Dashbaord,
    path: "/application/filteration-fail",
    component: lazy(() => import("views/application/filterationFail")),
  },
  // insurance apply
  {
    exact: true,
    layout: Dashbaord,
    path: "/application-insurance/apply",
    guard: HasApplicationIdOrUserVerifiedGuard,
    component: lazy(() => import("views/applicationInsurance/apply")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/application-insurance/apply-medical",
    guard: HasApplicationIdOrUserVerifiedGuard,
    component: lazy(() => import("views/applicationInsurance/apply-medical")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/application-insurance/car-insurance-type",
    guard: HasApplicationIdOrUserVerifiedGuard,
    component: lazy(() => import("views/applicationInsurance/car-insurance-type")),
  },
  // micro finance apply
  {
    exact: true,
    layout: Dashbaord,
    path: "/micro-finances/apply",
    guard: CheckIfUserVerified,
    component: lazy(() => import("views/microFinance/apply")),
  },
  // custom insurance product apply
  {
    exact: true,
    layout: Dashbaord,
    path: "/custom-insurance-product/apply",
    guard: CheckIfUserVerified,
    component: lazy(() => import("views/customInsuranceApplication/apply")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/custom-insurance-applications",
    guard: AuthGuard,
    component: lazy(() => import("views/SalesApplications/custom-insurance-applications")),
  },
  // free Agent
  {
    exact: true,
    layout: FreeAgentDashboard,
    path: "/free-agent",
    guard: FreeAgentGuard,
    component: lazy(() => import("views/freeAgent")),
  },

  // medical card
  {
    exact: true,
    layout: MarketerDashboard,
    path: "/medical-card",
    guard: AuthGuard,
    component: lazy(() => import("views/MedicalCard")),
  },

  {
    exact: true,
    layout: MarketerDashboard,
    path: "/medical-card/application",
    guard: AuthGuard,
    component: lazy(() => import("views/MedicalCard/application")),
  },
  {
    exact: true,
    layout: MarketerDashboard,
    path: "/medical-card/application/main-person",
    guard: AuthGuard,
    component: lazy(() => import("views/MedicalCard/application/MainPerson")),
  },
  {
    exact: true,
    layout: MarketerDashboard,
    path: "/medical-card/application/supplementary",
    guard: AuthGuard,
    component: lazy(() => import("views/MedicalCard/application/supplementary")),
  },
  {
    exact: true,
    layout: Dashbaord,
    path: "/404",
    component: lazy(() => import("views/NotFound")),
  },
  {
    component: () => <Navigate to="/404" />,
  },
];

export const renderRoutes = (routes) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          {routes.map((route, key) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;

            return (
              <Route
                key={key}
                path={route.path}
                element={
                  <Guard>
                    <Layout>
                      <Component />
                    </Layout>
                  </Guard>
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default routes;

