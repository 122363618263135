import axios from "axios";
import { store } from "store/store";
import { StoreActionsEnum } from "enum/store-actions.enum";
import { Navigate } from "react-router-dom";
import { istokenValid } from "helper";

const axiosInstance = axios.create({
  headers: {
    lang: "ar",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const url = config.url;

    // check for expiration of exist token if expired request new token and set new expiration date
    if (!istokenValid() && !url.includes("/login") && !url.includes("/verifyOtpCode") && !url.includes("/upgradePassword")) {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/refreshToken`,
        {},
        {
          headers: {
            lang: "ar",
            Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null,
          },
        }
      );
      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("expirationDate", data?.data?.userData?.expirationDate);
      config.headers["Authorization"] = `Bearer ${data?.data?.token}`;
    }
    config.headers["Authorization"] = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch({ type: StoreActionsEnum.CLEAR_APPLICATION_REDUCER });
      store.dispatch({ type: StoreActionsEnum.CLEAR_FILTER_REDUCER });
      store.dispatch({ type: StoreActionsEnum.CLEAR_USER_REDUCER });
      store.dispatch({ type: StoreActionsEnum.Auth_Logout });
      localStorage.removeItem("token");
      localStorage.removeItem("expirationDate");
      return <Navigate to="/login" />;
    }
    return Promise.reject(error.response || "Something went wrong");
  }
);

export default axiosInstance;
