import axios from "utils/axios";

export const getAllRewardTypes = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/rewardTypes`);
  return data;
};

export const getSortOptions = async (productTypeId: number) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/bankingSortOptions/${productTypeId}`);
  return data;
};

export const getFuelTypes = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/fuelTypes`);
  return data;
};

export const getDurations = async (productTypeId: number) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/durations?productTypeId=${productTypeId}`);
  return data;
};

export const getEmployeeTypes = async (productTypeId?: number) => {
  let url = `${process.env.REACT_APP_API_URL}/lookups/employeeTypes`;
  if (productTypeId) {
    url += `?productTypeId=${productTypeId}`;
  }
  const { data } = await axios.get(url);
  return data;
};
export const getSalaryTransferStatuses = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/salaryTransferStatuses`);
  return data;
};

export const getAllCurrencies = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/currencies`);
  return data;
};

export const getAllRateTypes = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/depositRateTypes`);
  return data;
};

export const getAllPaymentFrequencies = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/paymentFrequencies`);
  return data;
};

export const getDepositDurations = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/depositDurations`);
  return data;
};

export const getAllNationalities = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/nationalities`);
  return data;
};

export const getAllOtherIncomeSources = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/otherIncomeSources`);
  return data;
};

export const getCitiesWithAreas = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/citiesAreas`);
  return data;
};

export const getAllIndustries = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/industries`);
  return data;
};

export const getAllCompanySectors = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/companySectors`);
  return data;
};

export const getAllEmployeeTypes = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/employeeTypes`);
  return data;
};

export const getObligations = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/productObligations`);
  return data;
};

export const getAllBanks = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/banks`);
  return data.filter((bank) => Number(bank.id) !== 56);
};

export const getBankingSalesApplicationStatuses = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/getSalesApplicationBankingStatuses`);
  return data;
};

export const getInsuranceSalesApplicationStatuses = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/insuranceApplicationStatuses`);
  return data;
};

export const getAllWorkDurations = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/years`);
  return data;
};

export const getAllOccupations = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/occupations`);
  return data;
};

export const getAllCompanySizes = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/companySizes`);
  return data;
};

export const getAllEducationLevels = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/educationLevels`);
  return data;
};

export const getInsuranceSortOptions = async (productTypeId: number) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/insuranceSortOptions/${productTypeId}`);
  return data;
};

export const getApplicationDocuments = async (productTypeId: number, productId: number) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_INSURANCE_URL}/salesApplication/productDocuments/${productTypeId}/${productId}`);
  return data;
};

export const getAges = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/ages`);
  return data;
};

export const getDeductibles = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/deductibles`);
  return data;
};

export const getCarModels = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/carModels`);
  return data;
};

export const getCarBrands = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/carBrands`);
  return data;
};

export const getCarModelYears = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/carModelYears`);
  return data;
};

export const getMaritalStatuses = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/maritalStatuses`);
  return data;
};

export const getCityWithAreas = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/citiesAreas`);
  return data;
};

export const getAllCarType = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/carTypes`);
  return data;
};

export const getCarValues = async ({ carModelId, carModelYearId }: { carModelId: number; carModelYearId: number }) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_INSURANCE_URL}/carModelPrices/carPrices?carModelId=${carModelId}&carModelYearId=${carModelYearId}`
  );
  return res.data;
};

export const getMedicalCoverageAmounts = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/medicalCoverageAmounts`);
  return data;
};

export const getGender = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/genders`);
  return data;
};

export const getAllCardDurations = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/cardDurations`);
  return data;
};

export const getAllCustomInsuranceProductTypes = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/customInsuranceProductTypes`);
  return data;
};

export const getAllCompanies = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/lookups/companies`);
  return data;
};

