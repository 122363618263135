import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const HasApplicationIdOrUserVerifiedGuard = ({ children }) => {
  const { userReducer, applicationReducer } = useSelector((state: any) => {
    return { userReducer: state.userReducer, applicationReducer: state.applicationReducer };
  });

  return (applicationReducer.applicationId ||
    (userReducer.phone && userReducer.phoneVerified && applicationReducer.product && applicationReducer.productTypeId)) &&
    applicationReducer.productTypeId ? (
        <>{children}</>
  ) : (
    <Navigate to="/" />
  );
};

HasApplicationIdOrUserVerifiedGuard.propTypes = {
  children: PropTypes.node,
};

export default HasApplicationIdOrUserVerifiedGuard;
