import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
  },
}));

const AuthLayout = ({ children }) => {
  const classes = styles();

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          {children}
        </Grid>
      </Container>
    </Grid>
  );
};

export default AuthLayout;
