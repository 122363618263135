import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import RolesEnum from "enum/roles.enum";

const FreeAgentGuard = ({ children }) => {
  const { authReducer } = useSelector((state: any) => {
    return { authReducer: state.authReducer };
  });

  return authReducer.token && authReducer.userData && authReducer.userData.roleId === RolesEnum.FREE_AGENT_ROLE ? children : <Navigate to="/login" />;
};

FreeAgentGuard.propTypes = {
  children: PropTypes.node,
};

export default FreeAgentGuard;
