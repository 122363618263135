import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const HasAuthEmailGuard = ({ children }) => {
  const { authReducer } = useSelector((state: any) => {
    return { authReducer: state.authReducer };
  });

  return authReducer.email && authReducer.password ? children : <Navigate to="/login" />;
};

HasAuthEmailGuard.propTypes = {
  children: PropTypes.node,
};

export default HasAuthEmailGuard;
