import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const NotAuthGuard = ({ children }) => {
  const { authReducer } = useSelector((state: any) => {
    return { authReducer: state.authReducer };
  });

  return !authReducer.token || !authReducer.userData ? children : <Navigate to="/" />;
};

NotAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default NotAuthGuard;
