import { StoreActionsEnum } from "enum/store-actions.enum";

// Define initialState
const initialState = {
  application: null,
};

// Define the Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StoreActionsEnum.SET_MEDICAL_CARD_APPLICATION:
      return {
        ...state,
        ...action.application,
      };
    case StoreActionsEnum.SET_CLEAR_MEDICAL_CARD_APPLICATION:
      return initialState
    default:
      return state;
  }
}

