import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Grid,
  List,
  Menu,
  AppBar,
  Toolbar,
  ListItem,
  MenuItem,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {homeRoutes, homeSalesInsuranceRoutes} from "constant";
import { getBankingSalesApplicationStatuses, getInsuranceSalesApplicationStatuses } from "API/Lookups";
import { StoreActionsEnum } from "enum/store-actions.enum";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "API/Auth";
import RolesEnum from "../../enum/roles.enum";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    color: "White",
    textDecoration: "none",
    textTransform: "capitalize",
  },
}));

const Header = () => {
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [sideMenu, setSideMenu] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { bankingApplicationStatuses, insuranceApplicationStatuses,roleId } = useSelector((state: any) => {
    return {
      bankingApplicationStatuses: state.statusReducer.bankingApplicationStatuses,
      insuranceApplicationStatuses: state.statusReducer.insuranceApplicationStatuses,
      roleId: state?.authReducer?.userData?.roleId,
    };
  });

  useEffect(() => {
    (async () => {
      if (bankingApplicationStatuses?.length === 0) {
        const bankStatuses = await getBankingSalesApplicationStatuses();
        dispatch({ type: StoreActionsEnum.SET_BANKING_APPLICATION_STATUSES, bankingApplicationStatuses: bankStatuses });
      }

      if (insuranceApplicationStatuses?.length === 0) {
        const insuranceStatuses = await getInsuranceSalesApplicationStatuses();
        dispatch({ type: StoreActionsEnum.SET_INSURANCE_APPLICATION_STATUSES, insuranceApplicationStatuses: insuranceStatuses });
      }
    })();
  });

  const pickProduct = (path: string) => {
    dispatch({ type: StoreActionsEnum.SET_PRODUCT_PATH, productPath: path });
    dispatch({ type: StoreActionsEnum.CLEAR_APPLICATION_REDUCER });
    dispatch({ type: StoreActionsEnum.CLEAR_USER_REDUCER });
    if (path === "/micro-finances/apply") {
      return navigate("/verify-user-phone");
    }
    return navigate(`${path}`);
  };

  const clearSession = () => {
    dispatch({ type: StoreActionsEnum.CLEAR_APPLICATION_REDUCER });
    dispatch({ type: StoreActionsEnum.CLEAR_FILTER_REDUCER });
    dispatch({ type: StoreActionsEnum.CLEAR_USER_REDUCER });
    dispatch({ type: StoreActionsEnum.Auth_Logout });
    localStorage.removeItem("token");
    navigate("/login");
  };

  const routeToBanking = (route) => {
    navigate(`/banking-applications/${route.id}`);
    setSideMenu(false);
  };

  const routeToInsurance = (route) => {
    navigate(`/insurance-applications/${route.id}`);
    setSideMenu(false);
  };

  const routeToCustomInsurance = (route) => {
    navigate(`/custom-insurance-applications/${route.id}`);
    setSideMenu(false);
  };

  const sessionLogout = async () => {
    try {
      await logout();
      clearSession();
    } catch (err) {
      clearSession();
    }
  };

  const list = () => (
    <List>
      <ListItem button key="Home" onClick={() => navigate("/")}>
        <ListItemIcon>
          <HomeIcon style={{ width: "45px", height: "30px", borderRadius: "50%" }} color="secondary" />
        </ListItemIcon>
        <ListItemText primary="الرئيسية" />
      </ListItem>

      {
        roleId === RolesEnum.SALES_INSURANCE_ROLE ? homeSalesInsuranceRoutes.map(({ path, title, icon }) => (
        <ListItem button key={title} onClick={() => pickProduct(path)}>
          <ListItemIcon>
            <img width="45px" height="45px" src={icon} alt={title} />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      )) : homeRoutes.map(({ path, title, icon }) => (
            <ListItem button key={title} onClick={() => pickProduct(path)}>
              <ListItemIcon>
                <img width="45px" height="45px" src={icon} alt={title} />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
        ))}
      {/*// @ts-ignore*/}
      {roleId !== RolesEnum.SALES_INSURANCE_ROLE &&
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>ابليكيشن البنوك</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem button key={"All Bank Application"} onClick={() => navigate(`/banking-applications`)}>
                  <ListItemIcon>
                    <img width="30px" height="30px" src="/assets/file.svg" alt="All Bank Application"/>
                  </ListItemIcon>
                  <ListItemText primary="كل الأبلكيشن"/>
                </ListItem>
                {bankingApplicationStatuses?.map((route) => (
                    <ListItem button key={route.name} onClick={() => routeToBanking(route)}>
                      <ListItemIcon>
                        <img width="30px" height="30px" src="/assets/file.svg" alt={route.name}/>
                      </ListItemIcon>
                      <ListItemText primary={route.name}/>
                    </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
      }
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography>ابليكيشن التأمينات</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem button key={"All Insurance Application"} onClick={() => navigate(`/insurance-applications`)}>
              <ListItemIcon>
                <img width="30px" height="30px" src="/assets/file.svg" alt="All Insurance Application" />
              </ListItemIcon>
              <ListItemText primary="كل الابلكيشن" />
            </ListItem>
            {insuranceApplicationStatuses?.map((route) => (
              <ListItem button key={route.name} onClick={() => routeToInsurance(route)}>
                <ListItemIcon>
                  <img width="30px" height="30px" src="/assets/file.svg" alt={route.name} />
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      {roleId !== RolesEnum.SALES_INSURANCE_ROLE &&
          <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel3bh-content" id="panel3bh-header">
              <Typography>مشروعات متناهية الصغر</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem button key={"All Microfinance Application"}
                          onClick={() => navigate(`/microfinance-applications`)}>
                  <ListItemIcon>
                    <img width="30px" height="30px" src="/assets/file.svg" alt="All Microfinance Application"/>
                  </ListItemIcon>
                  <ListItemText primary="كل الابلكيشن"/>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
      }
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
          <Typography>ابليكشن التأمينات الأخري</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem button key={"All Custom Insurance Application"} onClick={() => navigate(`/custom-insurance-applications`)}>
              <ListItemIcon>
                <img width="30px" height="30px" src="/assets/file.svg" alt="All Custom Insurance Application" />
              </ListItemIcon>
              <ListItemText primary="كل الابلكيشن" />
            </ListItem>
            {insuranceApplicationStatuses?.map((route) => (
              <ListItem button key={route.name} onClick={() => routeToCustomInsurance(route)}>
                <ListItemIcon>
                  <img width="30px" height="30px" src="/assets/file.svg" alt={route.name} />
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </List>
  );

  return (
    <div className={classes.root}>
      <SwipeableDrawer open={sideMenu} onClose={() => setSideMenu(false)} onOpen={() => setSideMenu(true)}>
        {list()}
      </SwipeableDrawer>

      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => setSideMenu(!sideMenu)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon fontSize="large" style={{ color: "#fff" }} />
          </IconButton>

          <Typography variant="h5" align="center" className={classes.grow}>
            <Grid container alignItems="center" justifyContent="center">
              <img src="/assets/logo.svg" alt="logo" width="50px" height="50px" />
              <Link to="/" className={classes.title}>
                <strong>{pathname.split("/")[1]}</strong>
              </Link>
            </Grid>
          </Typography>

          <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
            <AccountCircle fontSize="large" style={{ color: "#fff" }} />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={sessionLogout}>تسجيل خروج</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;

