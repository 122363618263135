import { ThemeProvider } from "@material-ui/core";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import routes, { renderRoutes } from "navigation";
import "styles/index.css";
import theme from "theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {renderRoutes(routes)}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
