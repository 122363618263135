import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const HasApplicationIdGuard = ({ children }) => {
  const { applicationReducer } = useSelector((state: any) => {
    return { applicationReducer: state.applicationReducer };
  });

  return applicationReducer.applicationId ? children : <Navigate to="/" />;
};

HasApplicationIdGuard.propTypes = {
  children: PropTypes.node,
};

export default HasApplicationIdGuard;
