export enum StoreActionsEnum {
  // Auth Actions
  Auth_Login = "AUTH_LOGIN",
  Auth_Logout = "AUTH_LOGOUT",
  SET_AUTH_CREDENTIALS = "SET_AUTH_CREDENTIALS",

  // Application Actions
  SET_APPLICATION_ID = "SET_APPLICATION_ID",
  SET_PRODUCT = "SET_PRODUCT",
  SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE",
  SET_PRODUCT_DOCUMENTS = "SET_PRODUCT_DOCUMENTS",
  CLEAR_APPLICATION_REDUCER = "CLEAR_APPLICATION_REDUCER",

  // Filter Actions
  SET_PRODUCTS_FILTER = "SET_PRODUCTS_FILTER",
  SET_PRODUCT_PATH = "SET_PRODUCT_PATH",
  CLEAR_FILTER_REDUCER = "CLEAR_FILTER_REDUCER",

  // User Actions
  SET_USER_PHONE = "SET_USER_PHONE",
  SET_USER_PHONE_VERIFIED = "SET_USER_PHONE_VERIFIED",
  CLEAR_USER_REDUCER = "CLEAR_USER_REDUCER",

  // Statuses Actions
  SET_BANKING_APPLICATION_STATUSES = "SET_BANKING_APPLICATION_STATUSES",
  SET_INSURANCE_APPLICATION_STATUSES = "SET_INSURANCE_APPLICATION_STATUSES",

  // Medical Card Actions
  // SET_MEDICAL_CARD_APPLICATION_ID = "SET_MEDICAL_CARD_APPLICATION_ID",
  SET_MEDICAL_CARD_APPLICATION = "SET_MEDICAL_CARD_APPLICATION",
  SET_CLEAR_MEDICAL_CARD_APPLICATION = "SET_CLEAR_MEDICAL_CARD_APPLICATION",
}

