import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const CheckIfUserVerified = ({ children }) => {
  const { userReducer } = useSelector((state: any) => {
    return {
      userReducer: state.userReducer,
    };
  });
  return userReducer.phone && userReducer.phoneVerified ? children : <Navigate to="/login" />;
};

CheckIfUserVerified.propTypes = {
  children: PropTypes.node,
};

export default CheckIfUserVerified;
