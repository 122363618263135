import { CarModelYearEnum } from "enum/car-model-year.enum";
import { LookUpProtoType } from "types/LookUp";
import { ProductTypesEnum } from "enum/product-types.enum";
import { FILE_SIZE, SUPPORTED_FORMATS } from "constant";

export const generateRandomString = ({ length }: { length: number }): string => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const numberWithCommas = (x) => {
  if (!x) {
    return "";
  }

  x = String(x)?.replace(/,/g, "");
  if (isNaN(x)) {
    return x?.length > 0 ? Number(x.slice(0, -1)).toLocaleString() : null;
  }

  return Number(x).toLocaleString() === "0" ? null : Number(x).toLocaleString();
};

export const removeCommas = (x) => {
  return Number(String(x)?.replace(/,/g, ""));
};

export const hasLoadMore = (page: number, totalPages: number): boolean => {
  return page < totalPages;
};

export const convertYears = (year: number) => {
  const integerYears = Math.trunc(year / 12);
  const list: number[] = [];
  for (let i = 1; i <= integerYears; i++) {
    list.push(i);
  }
  return list;
};

export const paginationOptions = (custom: any, sizePerPageList: any, totalSize: any, perPage: any, page: any) => {
  return {
    custom,
    sizePerPageList,
    totalSize,
    perPage: perPage,
    page: page,
  };
};

export const paginate = (value: { page: number; pageSize: any }, urlFilterParams: any, setUrlFilterParams: (arg0: any) => void) => {
  let queryString = {
    ...urlFilterParams,
    page: value?.page + 1 || 1,
    perPage: value?.pageSize || 25,
  };
  setUrlFilterParams(queryString);
};

export const urlParamsGenerator = (jsonParams: any) => {
  Object.keys(jsonParams).forEach(
    (k) =>
      (jsonParams[k] === null || (typeof jsonParams[k] === "number" && isNaN(jsonParams[k]))) && jsonParams[k] !== undefined && delete jsonParams[k]
  );
  return new URLSearchParams(jsonParams).toString();
};

export const transformBirthDateToBackend = (date: string): string => {
  if (date) {
    const dateString = date?.split("-");
    return dateString[2] + "/" + dateString[1] + "/" + dateString[0];
  }
};

export const transformBirthDateFromBackend = (date: string): string => {
  if (date) {
    const dateString = date?.split("/");
    return dateString[2] + "-" + dateString[1] + "-" + dateString[0];
  }
  return "";
};

export const transformFinancialProductsToBackend = (financialProducts: Array<any>): Array<object> => {
  const products = financialProducts.map((product) => {
    let transformedProduct: any = {};
    if (product.type === ProductTypesEnum.CREDIT_CARD) {
      transformedProduct = {
        bankId: product.bankId,
        productTypeId: product.type,
        cardLimit: product.cardLimit,
        cardGuaranteedByCdOrTd: product?.cardGuaranteedByCdOrTd,
        cardDurationId: product?.cardDurationId,
      };
    } else {
      transformedProduct = {
        bankId: product.bankId,
        productTypeId: product.type,
        loanAmount: product.loanAmount,
        monthlyInstallment: product.monthlyInstallment,
        loanEndDateMonth: product.loanEndDate?.split("-")[1],
        loanEndDateYear: product.loanEndDate?.split("-")[0],
        loanBeenSixMonthsOld: product?.loanBeenSixMonthsOld,
      };
    }

    if (product.userObligationId) {
      transformedProduct.userObligationId = product.userObligationId;
    }

    if (product.id) {
      transformedProduct.id = product.id;
    }

    return transformedProduct;
  });

  return products;
};

export const transformNullValuesToStringInObjects = (object: object): any => {
  let transformedObject = {};
  for (const key in object) {
    transformedObject[key] = !object[key] && typeof object[key] !== "boolean" ? "" : object[key];
  }

  return transformedObject;
};

export const validateFile = (file) => {
  const validExtensions = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
  const maxSize = 10240;
  const fileSie = file.size / 1024;
  if (fileSie > maxSize) {
    return false;
  }
  if (!validExtensions.includes(file.type)) {
    return false;
  }
  return true;
};

export const checkForLastRequiredDocument = (documents: Array<any>, uploadedDocumentId: number): boolean => {
  let lastRequiredDocument = true;

  documents.forEach((document) => {
    if (document?.document?.id !== Number(uploadedDocumentId) && !document?.isOptional && document?.documentAlternatives?.length === 0) {
      lastRequiredDocument = false;
    } else if (document?.document?.id !== Number(uploadedDocumentId) && !document?.isOptional && document?.documentAlternatives?.length !== 0) {
      let alternativeFound = false;
      document?.documentAlternatives?.forEach((alternative) => {
        if (alternative?.documentId === Number(uploadedDocumentId)) {
          alternativeFound = true;
        }
      });
      lastRequiredDocument = alternativeFound;
    }
  });
  return lastRequiredDocument;
};

export const transformPersonalAndFinanceInfoFromBackend = (personalAndFinanceInfo: any): any => {
  const transformedData = transformNullValuesToStringInObjects({
    ...personalAndFinanceInfo,
    nationalNumber: personalAndFinanceInfo?.nationalNumber || "",
    cashTransfer: personalAndFinanceInfo?.cashTransfer ? "bank" : "cash",
    payRollBankId: personalAndFinanceInfo?.bankId,
    carBrandId: personalAndFinanceInfo?.applicationDetail?.carBrand?.id,
    carModelId: personalAndFinanceInfo?.applicationDetail?.carModel?.id,
    carModelYearId: personalAndFinanceInfo?.applicationDetail?.carModelYear?.id,
    carTypeId: personalAndFinanceInfo?.applicationDetail?.carType?.id,
    birthDate: transformBirthDateFromBackend(personalAndFinanceInfo.birthDate),
    hasBankStatement: personalAndFinanceInfo.hasBankStatement ? "yes" : "no",
    bankStatementId: personalAndFinanceInfo.hasBankStatement ? personalAndFinanceInfo.bankStatementId : "",
    financialProducts: personalAndFinanceInfo.financialProducts.map((product) => {
      return {
        id: product.id ?? null,
        userObligationId: product.userObligationId ?? null,
        type: product.productTypeId,
        bankId: product.bankId,
        loanAmount: product.loanAmount,
        monthlyInstallment: product.monthlyInstallment,
        loanEndDate:
          product?.loanEndDateYear + "-" + (product?.loanEndDateMonth < 10 ? "0" + product?.loanEndDateMonth : product?.loanEndDateMonth) + "-01",
        cardLimit: product?.cardLimit,
        cardDurationId: product?.cardDurationId,
        loanBeenSixMonthsOld: product?.loanBeenSixMonthsOld,
        cardGuaranteedByCdOrTd: product?.cardGuaranteedByCdOrTd,
      };
    }),
  });

  return transformedData;
};

export const getDateOfBirthLookups = () => {
  const years = [];
  const months = [];
  const days = [];

  const thisYear = new Date().getFullYear();
  const initialYear = thisYear - 75;

  for (let i = initialYear; i <= thisYear - 16; i += 1) {
    years.push({ id: i, name: String(i) });
  }

  for (let i = 1; i <= 12; i += 1) {
    months.push({ id: i, name: String(i) });
  }

  for (let i = 1; i <= 31; i += 1) {
    days.push({ id: i, name: String(i) });
  }

  return { years, days, months };
};
export const getDateOfBirthMedicalLookups = () => {
  const years = [];
  const months = [];
  const days = [];

  const thisYear = new Date().getFullYear();
  const initialYear = thisYear - 65;

  for (let i = initialYear; i <= thisYear; i += 1) {
    years.push({ id: i, name: String(i) });
  }

  for (let i = 1; i <= 12; i += 1) {
    months.push({ id: i, name: String(i) });
  }

  for (let i = 1; i <= 31; i += 1) {
    days.push({ id: i, name: String(i) });
  }

  return { years, days, months };
};

export const prepareApplicationQuery = ({ id, queryParamFilters }: { id?: number; queryParamFilters: string }): string => {
  let url = "?";
  if (id) {
    url += `applicationStatusId=${id}`;
  }

  if (queryParamFilters && id) {
    url += `&${queryParamFilters}`;
  }
  if (queryParamFilters && !id) {
    url += `${queryParamFilters}`;
  }
  return url;
};

export const getDurationOfFinanceList = (): Array<LookUpProtoType> => {
  const durations = [];

  for (let i = 6; i <= 24; i += 1) {
    durations.push({ id: i, name: `${i} ${i > 10 ? "شهر" : "شهور"}` });
  }

  return durations;
};

export const convertArabicNumbers = (x: any): string => {
  // return x.replace(/[٠-٩]/g, (d: string) => "٠١٢٣٤٥٦٧٨٩".indexOf(d))
  const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
  const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

  if (typeof x === "string") {
    for (let i = 0; i < 10; i += 1) {
      x = x.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
    return x;
  }
  return x;
};

export const checkHighDeductiblePercentageForYear = (carModelYearId?: number) => {
  if (carModelYearId) {
    const year: number = new Date().getFullYear();
    let firstYear = year - 5;
    const lastYear = year - 15;

    // @ts-ignore
    const carModelYearKey = Object.keys(CarModelYearEnum).find((key) => CarModelYearEnum[key] === carModelYearId);

    for (firstYear; lastYear <= firstYear; firstYear -= 1) {
      if (carModelYearKey === `YEAR_${firstYear}`) {
        return true;
      }
    }
    return false;
  }

  return false;
};

export const istokenValid = () => {
  const expirationDate = localStorage.getItem("expirationDate") ? new Date(localStorage.getItem("expirationDate")) : new Date();
  const today = new Date();

  if (today >= expirationDate) {
    return false;
  }

  return true;
};

export const FileSizeValidation = (value: File) => {
  if (value) {
    return value.size > 0 && value.size <= FILE_SIZE;
  }
  return value === null;
};

export const FileTypeValidation = (value: File) => {
  if (value) {
    return SUPPORTED_FORMATS.includes(value.type) !== false;
  }
  return value === null;
};

