import { StoreActionsEnum } from "enum/store-actions.enum";

// Define initialState
const initialState = {
  applicationId: null,
  product: null,
  productTypeId: null,
};

// Define the Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StoreActionsEnum.SET_APPLICATION_ID:
      return {
        ...state,
        applicationId: action.applicationId,
      };
    case StoreActionsEnum.SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case StoreActionsEnum.SET_PRODUCT_TYPE:
      return {
        ...state,
        productTypeId: action.productTypeId,
      };
    case StoreActionsEnum.CLEAR_APPLICATION_REDUCER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
