import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const HasUserPhoneGuard = ({ children }) => {
  const { userReducer } = useSelector((state: any) => {
    return { userReducer: state.userReducer };
  });

  return userReducer.phone ? children : <Navigate to="/verify-user-phone" />;
};

HasUserPhoneGuard.propTypes = {
  children: PropTypes.node,
};

export default HasUserPhoneGuard;
