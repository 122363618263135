import Grid from "@material-ui/core/Grid"
import Header from "layouts/MarketerDashboard/Header"
import Container from "@material-ui/core/Container"

const MarketerDashboard = ({ children }) => {
    return (
        <Grid>
            <Header />
            <Container>
                {children}
            </Container>
        </Grid>
    )
}

export default MarketerDashboard
