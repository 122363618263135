import { combineReducers } from "redux";
import authReducer from "./authReducer";
import applicationReducer from "./applicationReducer";
import filterReducer from "./filterReducer";
import userReducer from "./userReducer";
import statusReducer from "./statusReducer";
import medicalCardReducer from "./medicalCardReducer";

const rootReducer = combineReducers({
  authReducer,
  applicationReducer,
  filterReducer,
  userReducer,
  statusReducer,
  medicalCardReducer,
});

export default rootReducer;

