import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/rootReducer";

const logger = createLogger();

const persistConfig = {
  key: "faydety",
  whitelist: ["authReducer", "applicationReducer", "filterReducer", "userReducer", "statusReducer", "medicalCardReducer"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(logger));
export const persistor = persistStore(store);

