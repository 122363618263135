import axios from "utils/axios";

export const login = async ({ email, password }) => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, password });
  return data;
};

export const verifyOtp = async ({ otpCode, email, lat, long }) => {
  const headers: any = {};
  if (lat) {
    headers.lat = lat;
  }

  if (long) {
    headers.long = long;
  }

  return await axios.post(`${process.env.REACT_APP_API_URL}/auth/verifyOtpCode`, { otpCode, email }, { headers });
};

export const logout = async () => {
  const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
  return data;
};

export const updatePassword = async ({ oldPassword, newPassword, email }) => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/upgradePassword`, { oldPassword, newPassword, email });
  return data;
};

export const getSalesProducts = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admins/salesPersonProducts`);
  return data;
};

