import { StoreActionsEnum } from "enum/store-actions.enum";

// Define initialState
const initialState = {
  productFilter: null,
  productPath: null,
};

// Define the Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StoreActionsEnum.SET_PRODUCTS_FILTER:
      return {
        ...state,
        productFilter: action.productFilter,
      };
    case StoreActionsEnum.SET_PRODUCT_PATH:
      return {
        ...state,
        productPath: action.productPath,
      };
    case StoreActionsEnum.CLEAR_FILTER_REDUCER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
