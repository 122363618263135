import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
  root: {
    height: "100vh",
  },
});

const SplashScreen = () => {
  const classes = styles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.root}>
      <CircularProgress color="primary" size={120} />
    </Grid>
  );
};

export default SplashScreen;
