import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const HasProductPathGuard = ({ children }) => {
  const { filterReducer } = useSelector((state: any) => {
    return { filterReducer: state.filterReducer };
  });
  return filterReducer.productPath ? children : <Navigate to="/" />;
};

HasProductPathGuard.propTypes = {
  children: PropTypes.node,
};

export default HasProductPathGuard;
