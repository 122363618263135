export const CarModelYearEnum = {
  YEAR_2027: 18,
  YEAR_2026: 17,
  YEAR_2025: 16,
  YEAR_2024: 15,
  YEAR_2023: 14,
  YEAR_2011: 13,
  YEAR_2010: 12,
  YEAR_2022: 11,
  YEAR_2021: 10,
  YEAR_2020: 9,
  YEAR_2019: 8,
  YEAR_2018: 7,
  YEAR_2017: 6,
  YEAR_2016: 5,
  YEAR_2015: 4,
  YEAR_2014: 3,
  YEAR_2013: 2,
  YEAR_2012: 1,
};
