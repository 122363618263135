import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import typography from "theme/typography";
import breakpoints from "theme/breakpoints";

const theme = createTheme({
  direction: "rtl",
  typography,
  breakpoints,
  palette: {
    type: "light",
    primary: {
      main: "#56b27b",
    },
    secondary: {
      main: "#104f67",
    },
    text: {
      secondary: "#fff",
    },
    error: {
      main: "#ec4a59",
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: "23px",
      height: "45px",
      minWidth: "180px",
      fontWeight: 600,
      // maxWidth: "320px",
      // [theme.breakpoints.up("md")]: {
      //     maxWidth: "312px",
      // },
    },
    label: {
      fontSize: "12px",
    },
    contained: {
      color: "white",
      fontWeight: 700,
      backgroundColor: "#56b27b",
      "&:hover": {
        backgroundColor: "#499869",
      },
      "&:disabled": {
        color: "white",
        opacity: 0.4,
        backgroundColor: "#abd8bd",
      },
    },
    containedPrimary: {
      color: "white",
      fontSize: "16px",
      backgroundColor: "#56b27b",
      "&:hover": {
        backgroundColor: "#499869",
      },
      "&:disabled": {
        color: "white",
        opacity: 0.4,
        backgroundColor: "#abd8bd",
      },
    },
    containedSecondary: {
      color: "white",
      backgroundColor: "#104f67",
      "&:disabled": {
        color: "white",
        opacity: 0.5,
        backgroundColor: "#104f67",
      },
    },
    outlined: {
      border: "solid 1px #104f67",
      color: "#104f67",
      fontWeight: 600,
      "&:disabled": {
        border: "solid 1px #88a7b3",
        color: "#88a7b3",
      },
      "&::placeholder": {
        color: "#000",
      },
    },
    text: {
      textDecoration: "underline",
      color: "#56b27b",
      "&:disabled": {
        textDecorationColor: "#abd8bd",
        color: "#abd8bd",
      },
    },
  },
  MuiDrawer: {
    root: {
      backgroundColor: "none",
    },
    paper: {
      width: "250px",
    },
  },
  MuiDivider: {
    root: {
      margin: "20px 0px",
    },
  },
  MuiCheckbox: {
    root: {
      color: "#495057",
    },
    input: {
      border: "1px solid red",
    },
  },
  MuiInput: {
    root: {
      "&:focus": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
      "&:active": {
        borderBottom: "none",
      },
    },
    underline: {
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "none",
      },
      "&:hover:not($disabled):before": {
        borderBottom: "none",
      },
      "&:disabled": {
        "&:before": {
          borderBottom: "none",
        },
      }
    },
  },
  MuiTextField: {
    root: {
      borderRadius: "4px",
      backgroundColor: "#e9ecef",
      height: "43px",
      color: "#495057",
      border: "none",
      minWidth: "100%",
      "&:disabled": {
        border: "1px solid rgb(16, 79, 103)",
        backgroundColor: "#e9ecef !important",
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: "12px 14px",
      textAlign: "right",
      color: "#495057",
      "&:disabled": {
        backgroundColor: "#e9ecef !important",
      },
    },
    notchedOutline: {
      borderWidth: "0.5px",
    },
    adornedStart: {
      paddingLeft: "0px !important",
      backgroundColor: "#ced4da !important",
    },
  },
  MuiSelect: {
    outlined: {
      color: "#495057",
    },
  },
  MuiInputAdornment: {
    root: {
      margin: "0px 5px",
    },
  },
  MuiTypography: {
    root: {
      textDecoration: "none",
    },
    colorSecondary: {
      textDecoration: "none",
    },
    body2: {
      fontSize: "16px",
    },
    subtitle2: {
      color: "#4e545a",
    },
  },
  MuiMenu: {
    list: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  MuiCardContent: {
    root: {
      padding: "8px 16px",
    },
  },
  MuiButtonGroup: {
    groupedContainedHorizontal: {
      "&:not(&:last-child)": {
        borderRight: "none"
      }
    }
  },
  MuiRadio: {
    root: {
      color: "#104f67"
    }
  },
  MuiFormLabel: {
    root: {
      color: "#104f67"
    }
  },
  MuiStepLabel: {
    label: {
      color: "rgba(0, 0, 0, 0.87)"
    }
  },
  MuiStepConnector: {
    line: {
      display: "none"
    }
  },

};

export default responsiveFontSizes(theme);
