import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import RolesEnum from "enum/roles.enum";

const NotFreeAgentGuard = ({ children }) => {
  const { roleId } = useSelector((state: any) => {
    return { roleId: state.authReducer.userData.roleId };
  });

  return roleId !== RolesEnum.FREE_AGENT_ROLE ? children : <Navigate to="/free-agent" />;
};

NotFreeAgentGuard.propTypes = {
  children: PropTypes.node,
};

export default NotFreeAgentGuard;
