import { InsuranceProductTypes } from "enum/insurance-product-types.enum";
import { ProductTypesEnum } from "enum/product-types.enum";

export const MarketerRoutes = [
  {
    path: "/medical-card",
    title: "كير بلس",
    titleArabic: "كير بلس",
    icon: "/assets/home/medical-card.svg",
  },
];

export const homeRoutes = [
  {
    path: "/personal-loans",
    title: "قروض شخصية",
    titleArabic: "قروض شخصية",
    icon: "/assets/home/personal_loan.svg",
    bankingProductTypeId: ProductTypesEnum.PERSONAL_LOAN,
    insuranceProductTypeId: null,
  },
  {
    path: "/home-loans",
    title: "قروض منازل",
    titleArabic: "قروض منازل",
    icon: "/assets/home/home-loan.svg",
    bankingProductTypeId: ProductTypesEnum.HOME_LOAN,
    insuranceProductTypeId: null,
  },
  {
    path: "/car-loans",
    title: "قروض سيارات",
    titleArabic: "قروض سيارات",
    icon: "/assets/home/car_loan.svg",
    bankingProductTypeId: ProductTypesEnum.CAR_LOAN,
    insuranceProductTypeId: null,
  },
  {
    path: "/credit-cards",
    title: "بطاقات ائتمانيه",
    titleArabic: "بطاقات ائتمانيه",
    icon: "/assets/home/credit_cards_icon.svg",
    bankingProductTypeId: ProductTypesEnum.CREDIT_CARD,
    insuranceProductTypeId: null,
  },
  {
    path: "/deposits",
    title: "شهادات و ودائع",
    titleArabic: "شهادات و ودائع",
    icon: "/assets/home/deposits_icon.svg",
    bankingProductTypeId: ProductTypesEnum.TIME_DEPOSIT,
    insuranceProductTypeId: null,
  },
  {
    path: "/personal-accidents",
    title: "تأمين الحوادث شخصية",
    titleArabic: "تأمين الحوادث شخصية",
    icon: "/assets/home/personal-accident.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.PERSONAL_ACCIDENT,
  },
  {
    path: "/home-insurances",
    title: "تأمين المنازل",
    titleArabic: "تأمين المنازل",
    icon: "/assets/home/home-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.HOME_INSURANCE,
  },
  {
    path: "/car-insurances",
    title: "تأمين السيارات",
    titleArabic: "تأمين السيارات",
    icon: "/assets/home/car-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.CAR_INSURANCE,
  },
  {
    path: "/fire-theft-insurances",
    title: "تأمين ضد الحرائق و السطو",
    titleArabic: "تأمين ضد الحرائق و السطو",
    icon: "/assets/home/fire-theft-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.FIRE_THEFT_INSURANCE,
  },
  {
    path: "/medical-insurances",
    title: "تأمين طبى",
    titleArabic: "تأمين طبى",
    icon: "/assets/family-medical-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.MEDICAL_INSURANCE,
  },
  {
    path: "/micro-finances/apply",
    title: "مشروعات متناهيه الصغر",
    titleArabic: "مشروعات متناهيه الصغر",
    icon: "/assets/home/micro-finance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: null,
  },
  {
    path: "/custom-insurance-product/apply",
    title: "منتجات تأمينيه أخري",
    titleArabic: "منتجات تأمينيه أخري",
    icon: "/assets/home/custom-insurance-application.png",
    bankingProductTypeId: null,
    insuranceProductTypeId: null,
  },
];

export const homeSalesInsuranceRoutes = [
  {
    path: "/personal-accidents",
    title: "تأمين الحوادث شخصية",
    titleArabic: "تأمين الحوادث شخصية",
    icon: "/assets/home/personal-accident.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.PERSONAL_ACCIDENT,
  },
  {
    path: "/home-insurances",
    title: "تأمين المنازل",
    titleArabic: "تأمين المنازل",
    icon: "/assets/home/home-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.HOME_INSURANCE,
  },
  {
    path: "/car-insurances",
    title: "تأمين السيارات",
    titleArabic: "تأمين السيارات",
    icon: "/assets/home/car-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.CAR_INSURANCE,
  },
  {
    path: "/fire-theft-insurances",
    title: "تأمين ضد الحرائق و السطو",
    titleArabic: "تأمين ضد الحرائق و السطو",
    icon: "/assets/home/fire-theft-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.FIRE_THEFT_INSURANCE,
  },
  {
    path: "/medical-insurances",
    title: "تأمين طبى",
    titleArabic: "تأمين طبى",
    icon: "/assets/family-medical-insurance.svg",
    bankingProductTypeId: null,
    insuranceProductTypeId: InsuranceProductTypes.MEDICAL_INSURANCE,
  },
  {
    path: "/custom-insurance-product/apply",
    title: "منتجات تأمينيه أخري",
    titleArabic: "منتجات تأمينيه أخري",
    icon: "/assets/home/custom-insurance-application.png",
    bankingProductTypeId: null,
    insuranceProductTypeId: null,
  },
];

export const InsurancePath = ["/personal-accidents", "/home-insurances", "/car-insurances", "/medical-insurances", "/fire-theft-insurances"];

export const coverageAmounts = [
  // { id: 25000, name: "25,000" },
  { id: 50000, name: "50,000" },
  { id: 75000, name: "75,000" },
  { id: 100000, name: "100,000" },
  { id: 150000, name: "150,000" },
  { id: 200000, name: "200,000" },
  { id: 300000, name: "300,000" },
  { id: 500000, name: "500,000" },
];
export const checkUpperLower50Options = [
  { id: 1, name: "+/- 50" },
  { id: 0, name: "لا" },
];

export const FILE_SIZE = 10000000;

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

