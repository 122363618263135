const breakpoints = {
  values: {
    xs: 300,
    sm: 768,
    md: 992,
    lg: 1150,
    xl: 1440,
  },
};

export default breakpoints;
