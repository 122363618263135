import { StoreActionsEnum } from "enum/store-actions.enum";

// Define initialState
const initialState = {
  userData: null,
  token: null,
  email: null,
  password: null,
};

// Define the Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StoreActionsEnum.Auth_Login:
      return {
        ...state,
        userData: action.userData,
        token: action.token,
      };
    case StoreActionsEnum.SET_AUTH_CREDENTIALS:
      return {
        ...state,
        email: action.email,
        password: action.password,
      };
    case StoreActionsEnum.Auth_Logout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
