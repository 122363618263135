import { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Grid,
  List,
  Menu,
  AppBar,
  Toolbar,
  ListItem,
  MenuItem,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { StoreActionsEnum } from "enum/store-actions.enum";
import { useDispatch } from "react-redux";
import { logout } from "API/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    color: "White",
    textDecoration: "none",
    textTransform: "capitalize",
  },
}));

const Header = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [sideMenu, setSideMenu] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();


  const clearSession = () => {
    dispatch({ type: StoreActionsEnum.Auth_Logout });
    localStorage.removeItem("token");
    navigate( "/login");
  };

  const sessionLogout = async () => {
    try {
      await logout();
      clearSession();
    } catch (err) {
      console.error(err);
      clearSession();
    }
  };

  const list = () => (
    <List>
      <ListItem button key="Home" onClick={() => navigate("/free-agent")}>
        <ListItemIcon>
          <HomeIcon style={{ width: "45px", height: "30px", borderRadius: "50%" }} color="secondary" />
        </ListItemIcon>
        <ListItemText primary="احصائياتى" />
      </ListItem>
    </List>
  );

  return (
    <div className={classes.root}>
      <SwipeableDrawer open={sideMenu} onClose={() => setSideMenu(false)} onOpen={() => setSideMenu(true)}>
        {list()}
      </SwipeableDrawer>

      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => setSideMenu(!sideMenu)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon fontSize="large" style={{ color: "#fff" }} />
          </IconButton>

          <Typography variant="h5" align="center" className={classes.grow}>
            <Grid container alignItems="center" justifyContent="center">
              <img src="/assets/logo.svg" alt="logo" width="50px" height="50px" />
              <Link to="/" className={classes.title}>
                <strong>{pathname.split("/")[1]}</strong>
              </Link>
            </Grid>
          </Typography>

          <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
            <AccountCircle fontSize="large" style={{ color: "#fff" }} />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={sessionLogout}>تسجيل خروج</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
