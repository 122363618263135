import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NotFreeAgentGuard from "guard/NotFreeAgentGuard";

const HasProductIdOrApplicationIdGuard = ({ children }) => {
  const { applicationReducer, userReducer } = useSelector((state: any) => {
    return { applicationReducer: state.applicationReducer, userReducer: state.userReducer };
  });

  return applicationReducer.applicationId || (applicationReducer.productTypeId && userReducer.phone) ? (
    <NotFreeAgentGuard>{children}</NotFreeAgentGuard>
  ) : (
    <Navigate to="/" />
  );
};

HasProductIdOrApplicationIdGuard.propTypes = {
  children: PropTypes.node,
};

export default HasProductIdOrApplicationIdGuard;

