import { StoreActionsEnum } from "enum/store-actions.enum"

// Define initialState
const initialState = {
  phone: null,
  phoneVerified: false
}

// Define the Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StoreActionsEnum.SET_USER_PHONE:
      return {
        ...state,
        phone: action.phone
      }
    case StoreActionsEnum.SET_USER_PHONE_VERIFIED:
      return {
        ...state,
        phoneVerified: action.phoneVerified
      }
    case StoreActionsEnum.CLEAR_USER_REDUCER:
      return {
        ...initialState,
      }
    default:
      return state
  }
}