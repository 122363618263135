import { StoreActionsEnum } from "enum/store-actions.enum";

// Define initialState
const initialState = {
  bankingApplicationStatuses: [],
  insuranceApplicationStatuses: [],  
};

// Define the Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StoreActionsEnum.SET_BANKING_APPLICATION_STATUSES:
      return {
        ...state,
        bankingApplicationStatuses: action.bankingApplicationStatuses,
      };
    case StoreActionsEnum.SET_INSURANCE_APPLICATION_STATUSES:
      return {
        ...state,
        insuranceApplicationStatuses: action.insuranceApplicationStatuses,
      };
    default:
      return state;
  }
}
